import React from "react";
import Layout from "../components/_App/layout";
import SEO from "../components/_App/seo"; 
import Navbar from "../components/_App/Navbar";
import Footer from "../components/_App/Footer";
import PageBanner from '../components/Common/PageBanner'; 
import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemPanel,
    AccordionItemButton
} from 'react-accessible-accordion';

const FAQ = () => (
    <Layout>
        <SEO title="FAQ" /> 

        <Navbar />

        <PageBanner pageTitle="FAQ" />

        <div className="faq-area ptb-80">
            <div className="container">
                <div className="faq-accordion">
                    <Accordion allowZeroExpanded preExpanded={['a']}>
                        <AccordionItem uuid="a">
                            <AccordionItemHeading>
                                <AccordionItemButton>
                                    <span>
                                        When do we get to see your first app?
                                    </span>
                                </AccordionItemButton>
                            </AccordionItemHeading>
                            <AccordionItemPanel>
                                <p>
                                    StoryToons is hard at work in creating amazing content and rich features for your kids! We're aiming for a fall 2022 release.
                                </p>
                            </AccordionItemPanel>
                        </AccordionItem>

                        <AccordionItem uuid="b">
                            <AccordionItemHeading>
                                <AccordionItemButton>
                                    <span>
                                        Will I be able to track the progress of my children?
                                    </span>
                                </AccordionItemButton>
                            </AccordionItemHeading>
                            <AccordionItemPanel>
                                <p>
                                    Yes! We'll have a very secure web portal for you to see how your child is progressing through the app!
                                </p>
                            </AccordionItemPanel>
                        </AccordionItem>

                        <AccordionItem uuid="c">
                            <AccordionItemHeading>
                                <AccordionItemButton>
                                    <span>
                                        How much will it cost?
                                    </span>
                                </AccordionItemButton>
                            </AccordionItemHeading>
                            <AccordionItemPanel>
                                <p>
                                    We're still trying to figure out the best price for the superb and unique value we'll be providing. But we'll provide a freemium model without those annoying ads!
                                </p>
                            </AccordionItemPanel>
                        </AccordionItem>
                    
                        <AccordionItem uuid="d">
                            <AccordionItemHeading>
                                <AccordionItemButton>
                                    <span>
                                        Where is StoryToons located?
                                    </span>
                                </AccordionItemButton>
                            </AccordionItemHeading>
                            <AccordionItemPanel>
                                <p>
                                    We're based in Seattle, Washington!
                                </p>
                            </AccordionItemPanel>
                        </AccordionItem>

                        <AccordionItem uuid="e">
                            <AccordionItemHeading>
                                <AccordionItemButton>
                                    <span>
                                        What kind of genres will be available?
                                    </span>
                                </AccordionItemButton>
                            </AccordionItemHeading>
                            <AccordionItemPanel>
                                <p>
                                    StoryToons will have a wide-range of different story genres and other categories of content.
                                </p>
                            </AccordionItemPanel>
                        </AccordionItem>
                    </Accordion>
                </div>

                <div className="faq-contact">
                    <h3>Ask Your Question</h3>
                    <form>
                        <div className="row">
                            <div className="col-lg-6 col-md-6">
                                <div className="form-group">
                                    <input type="text" placeholder="Name" className="form-control" />
                                </div>
                            </div>
                            
                            <div className="col-lg-6 col-md-6">
                                <div className="form-group">
                                    <input type="email" placeholder="Email" className="form-control" />
                                </div>
                            </div>
                            
                            <div className="col-lg-12 col-md-12">
                                <div className="form-group">
                                    <input type="text" placeholder="Subject" className="form-control" />
                                </div>
                            </div>
                            
                            <div className="col-lg-12 col-md-12">
                                <div className="form-group">
                                    <textarea name="message" cols="30" rows="6" placeholder="Message" className="form-control"></textarea>
                                </div>
                            </div>
                            
                            <div className="col-lg-12 col-md-12">
                                <button className="btn btn-primary" type="submit">Submit Now!</button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>

        <Footer />
    </Layout>
)

export default FAQ;